import React, { useRef } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import Img from "gatsby-image";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import ALink from "../../components/a-link/a-link";

import "./privacy-policy.scss";

import CallFooter from "../../components/call-footer/call-footer";
import Brands from "../../components/brands/brands";
import Customers from "../../components/customers/customers";
import References from "../../components/references/references";
import Technologies from "../../components/technologies/technologies";
import BoxList from "../../components/box-list/box-list";
import HeaderBig from "../../components/header-big/header-big";
import Hero from "../../components/hero/hero";
import ScrollButton from "../../components/scroll-button/scroll-button";

const PrivacyPolicyPage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  return (
    <>
      <SEO title="Polityka prywatności | Agencja Internetowa Aplinet" />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Polityka prywatności | Agencja Interaktywna Aplinet</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Polityka prywatności strony internetowej, serwisu - aplinet.pl
            </h2>
          </div>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>
      {/* -----------------------------------------` */}
      <section className="container-xs" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Drogi Użytkowniku!"
          headerText="Polityka prywatności"
        />
        <p>
          W&nbsp;trosce o&nbsp;Twoje bezpieczeństwo, prywatność i&nbsp;komfort
          użytkowania serwisu <strong>aplinet.pl</strong>, przedstawiamy Ci
          najważniejsze informacje o&nbsp;zasadach przetwarzania danych
          osobowych oraz plików cookies przez naszą firmę.
          <br />
          <i>
            Informacje te zostały przygotowane z&nbsp;uwzględnieniem wymagań
            RODO (Ogólnego rozporządzenia o&nbsp;ochronie danych)
          </i>
          .
        </p>
        <HeaderBig
          tag="h3"
          span="01"
          headerText="Administratorzy danych osobowych"
        />

        <p>
          Przedsiębiorcy wpisani do Centralnej Ewidencji i&nbsp;Informacji
          o&nbsp;Działalności Gospodarczej prowadzonej przez ministra właściwego
          do spraw gospodarki i&nbsp;prowadzenia Centralnej Ewidencji
          i&nbsp;Informacji o&nbsp;Działalności Gospodarczej
        </p>
        <ul>
          <li>
            a) Piotr Dziurla, NIP 514 022 99 23, nr REGON 302382315, działający
            pod nazwą APLINET - PIOTR DZIURLA
          </li>
          <li>
            b) Konrad Frysiak, NIP 972 115 11 59, nr REGON 302721579, działający
            pod nazwą Sanpo - Konrad Frysiak
          </li>
        </ul>
        <HeaderBig
          tag="h3"
          span="02"
          headerText="Dane osobowe i&nbsp;prywatność"
        />
        <p>
          Korzystając z&nbsp;usług Agencji Interaktywnej Aplinet, zostaniesz
          poproszony o&nbsp;podanie nam Twoich danych osobowych. Twoje dane
          przetwarzane są przez nas we wskazanych poniżej celach, związanych
          z&nbsp;funkcjonowaniem Serwisu internetowego i&nbsp;świadczeniem usług
          oferowanych przez Agencję Aplinet.
        </p>
        <HeaderBig
          tag="h3"
          span="04"
          headerText="Cel przetwarzania danych wrażliwych"
        />
        <p>
          Wyłącznie, świadczenie usług oferowanych przez Agencję Internetową
          Aplinet.
        </p>
        <HeaderBig
          tag="h3"
          span="05"
          headerText="Podstawa przetwarzania danych wrażliwych"
        />
        <p>
          Wyrażenie zgody na przetwarzanie danych osobowych na stronie
          internetowej aplinet.pl - jest dobrowolne (art. 6&nbsp;ust. 1 lit.
          a&nbsp;RODO);
          <br />
          Umowa o&nbsp;świadczenie usług lub podejmowane działań na żądanie,
          zmierzające do jej zawarcia (art. 6&nbsp;ust. 1&nbsp;lit.
          b&nbsp;RODO);
          <br />
          Uzasadnione przypadki, polegające na przetwarzaniu danych w&nbsp;celu
          ustalenia, dochodzenia lub obrony ewentualnych roszczeń (art.
          6&nbsp;ust. 1&nbsp;lit. f&nbsp;RODO);
          <br />
          Uzasadnione przypadki (art. 6&nbsp;ust. 1&nbsp;lit. f&nbsp;RODO),
          polegające na:
        </p>
        <ul>
          <li>
            przetwarzaniu danych w&nbsp;celach profilowania przedsiębiorstwa,
          </li>
          <li>przetwarzaniu danych w&nbsp;celach marketingowych,</li>
          <li>przetwarzaniu danych analitycznych i&nbsp;statystycznych.</li>
        </ul>
        <HeaderBig tag="h3" span="03" headerText="Podanie danych wrażliwych" />
        <p>
          Podawnie dancyh osobowych jest dobrowolne. W&nbsp;niektórych
          przypadkach udzielenie informacji może być konieczne do zawarcia
          umowy.
        </p>
        <HeaderBig
          tag="h3"
          span="06"
          headerText="Brak podania danych wrażliwych"
        />
        <p>
          Brak podania danych osobowych, może uniwmożliwić korzystanie
          z&nbsp;niektórych usług serwisu aplinet.pl, oraz odpowiedzi na
          zapytanie ofertowe.
        </p>
        <HeaderBig
          tag="h3"
          span="07"
          headerText="Możliwość cofnięcia zgody przetwarzania danych wrażliwych"
        />
        <p>
          W&nbsp;każdej chwili przysłyguje Tobie możliwość cofnięcia zgody na
          przetwarzanie Twoich danych osobowych. Przetwarzanie danych do momentu
          cofnięcia przez Ciebie zgody pozostaje zgodne z&nbsp;prawem
          i&nbsp;polityką prywatności RODO.
        </p>
        <HeaderBig tag="h3" span="03" headerText="Profilowanie" />
        <p>
          W&nbsp;ramach Serwisu możemy automatycznie dopasowywać pewne treści do
          Twoich potrzeb, tj. dokonywać profilowania, wykorzystując do tego
          podane przez Ciebie dane osobowe.
          <br />
          Zanim dokonamy profilowania, na podstawie którego podejmowane będą
          decyzje: wywołujące wobec Ciebie skutki prawne, wpływające na Ciebie
          w&nbsp;podobnie istotny sposób - zapytamy Cię o&nbsp;zgodę.
        </p>
        <HeaderBig
          tag="h3"
          span="08"
          headerText="Okres przetwarzania danych wrażliwych"
        />
        <p>
          Twoje dane będziemy przetwarzać tylko przez okres, w&nbsp;którym
          będziemy mieć ku temu podstawę prawną, a&nbsp;więc do momentu,
          w&nbsp;którym:
        </p>
        <ul>
          <li>
            możliwość dochodzenia roszczeń związanych z&nbsp;umową zawartą przez
            Agencję Interaktywną Aplinet
          </li>
          <li>cofniesz zgodę na przetwarzanie danych</li>
        </ul>
        <HeaderBig
          tag="h3"
          span="09"
          headerText="Bezpieczeństwo danych wrażliwych"
        />
        <p>
          Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne
          i&nbsp;techniczne zgodne z&nbsp;właściwymi przepisami prawa.
        </p>
        <HeaderBig tag="h3" span="11" headerText="Ciasteczka" />
        <p>
          Nasz Serwis, tak jak większość stron internetowych, korzysta
          z&nbsp;plików cookies (ciasteczek). zapisanych w&nbsp;pamięci
          urządzenia na którym wyświetlasz stronę aplinet.pl. Pliki nie powodują
          zmian w&nbsp;ustawieniach urządzeń na których są zapisywane.
        </p>
        <p>
          W&nbsp;opcjach przeglądarki internetowej, w&nbsp;każdej chwili możesz
          usunąć pliki cookies oraz blokować ich wykorzystanie
          w&nbsp;przyszłości.
        </p>
        <p>
          Na stronie internetowej aplinet.pl, ciasteczka są wykorzystywane do:
        </p>
        <ul>
          <li>zapamiętywania informacji o&nbsp;sesji użytkownika,</li>
          <li>w&nbsp;celu statystycznym,</li>
          <li>w&nbsp;celu marketingowym.</li>
        </ul>
        <p>
          Aby uzyskać więcej informacji dotyczących zarządzania plikami cookies,
          możesz skorzystać z&nbsp;pliku pomocy przeglądarki internetowej
          wciskając klawisz F1 w&nbsp;przeglądarce. Więcej wskazówek znajdziesz
          na podstronach:
        </p>
        <ul>
          <li>
            <a
              href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
              target="_blank"
            >
              Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=plX"
              target="_blank"
            >
              Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
              target="_blank"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
              target="_blank"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
              target="_blank"
            >
              Microsoft Edge lub Internet Explorer
            </a>
          </li>
        </ul>
        <HeaderBig
          tag="h3"
          span="12"
          headerText="Usługi zewnętrzne / odbiorcy danych"
        />
        <p>
          Korzystamy z&nbsp;usług podmiotów zewnętrznych, którym mogą być
          przekazywane Twoje dane. Lista tych podmiotów to:
        </p>
        <ul>
          <li>
            podwykonawcy współpracujący z&nbsp;Agencją Internetową Aplinet na
            podstawie umowy cywilnoprawnej,
          </li>
          <li>firmy hostingowe,</li>
          <li>systemy mailingowe,</li>
          <li>portale społecznościowe.</li>
        </ul>
        <HeaderBig
          tag="h3"
          span="13"
          headerText="Przekazywanie danych osobowych do Państw spoza Unii Europejskiej"
        />
        <p>
          W&nbsp;związku z&nbsp;powyższym, Twoje dane osobowe mogą być
          przetwarzane również przez podmioty spoza Unii Europejskiej. Właściwy
          poziom ochrony Twoich danych, w&nbsp;tym poprzez stosowanie
          odpowiednich zabezpieczeń, zapewnia uczestnictwo tych podmiotów
          w&nbsp;Tarczy Prywatności UE-USA, ustanowionej decyzją wykonawczą
          Komisji Europejskiej jako zbiór zasad gwarantujących odpowiednią
          ochronę Twojej prywatności.
        </p>
        <HeaderBig
          tag="h3"
          span="14"
          headerText="Kontakt z&nbsp;administratorem"
        />
        <p>
          Chcesz skorzystać ze swoich uprawnień dotyczących danych osobowych lub
          masz pytania o&nbsp;naszą Politykę Prywatności? Napisz na adres
          e-mail: kontakt@aplinet.pl .
        </p>
      </section>
    </>
  );
};

export default PrivacyPolicyPage;
